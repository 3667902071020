<template>
  <left-and-right>
    <div slot="leftDiv">
      <div class="content">
        <h4>西部国际服务贸易联盟
          倡议书</h4>
        <h5>Proposal</h5>
        <p>
          陕西西咸新区服务贸易协会自愿组建“西部国际服务贸易大联盟”，共同建立公共服务平台，形成预警机制和产业技术创新链，提升行业核心竞争能力，为促进陕西及至西部服务贸易的发展贡献我们的力量。
          为此我们倡议：</p>
        <p>
          一、西部国际服务贸易联盟是相关产业机构自发、自愿组建的开放式、松散型行业联系组织。旨在促进同行间信息沟通、信用咨询、业务合作、资源共享、调查研究、经验交流、政策协调和行业自律。不论隶属关系、不论企业性质，都可以自愿加入。
        </p>
        <p>二、西部国际服务贸易联盟旨在加强信息沟通。入盟成员同意在联盟内互赠内部报刊、文献资料，建立网站链接，实现信息互通。
        </p>
        <p>三、西部国际服务贸易联盟鼓励开展业务合作。通过总揽分包、合作创益和培养潜在价值等方式，积极拓展业务领域。对联盟成员共同关心的议题，组织定期或不定期的知识、技术和信息交流研讨。
        </p>
        <p>四、西部国际服务贸易联盟将鬼魂资源共享。入盟成员互相开放经营资源，可以在对方互相设点，互相以优惠条件共享对方资源。
        </p>
        <p>五、西部国际服务贸易联盟将组织调查研究。入盟成员对服务功能、运营模式等组织调查研究，互相提供相关资料，共同探讨知识服务技术研究的发展方向，共享研究成果。
        </p>
        <p>六、西部国际服务贸易联盟将组织经验交流。入盟成员以及进驻单位在模式设计、招商融资、日常运营和监管服务等方面创造的经验，只要不涉及商业机密，都可以侧过多种方式进行交流。
        </p>
        <p>七、西部国际服务贸易联盟将促进政策协调。入盟成员应及时通报当地和行业的优惠政策，以利于其他成员共同分享，及时提出遇到的相关政策问题，以便以行业组织的名义集中反映。
        </p>
        <p>
          八、西部国际服务贸易联盟倡导行业自律。入盟成员理应认真学习，贯彻党和国家、政府的方针政策，自觉遵守法律法规，遵守联盟章程，坚持诚信，追求卓越，渴望变革，积极参与行业标准和统计工作，创造条件，制定行规，共同促进园区规范健康。
        </p>
        <p>我们热忱欢迎国内外业界随时加入我们的联盟！
        </p>
        <p>我们是命运的共同体，我们将承载使命，提纲重任，命运共载，风险同担，人才共聚，价值同享，西部服务贸易联盟携九州兄弟，聚八方面来客，汇全球智慧，共创伟业！
        </p>
        <p>我们是利益共同体，我们将相互关爱，相互容纳，相互支持，无私奉献，共克时艰。联盟是个大家庭，联盟是个大学校，联盟是个大赛场，让我们以博大感恩胸怀，创造天下财富，造福社会！
        </p>
        <p>我们将广泛吸纳境内外商协会、专业组织及知名企业加入，推动建立行业沟通新渠道，搭建行业交流新平台，构建资源共享新机制，力争在年底前完成组建工作。
        </p>
        <p>在当今世界大发展、大变革、大调整的新形势下，服务业和服务贸易的战略地位更加突出，已成为推动全球经济发展的新动能、新引擎。这要求服务贸易产业界必须顺应时代潮流、创新贸易方式、整合全球资源、合作共赢发展。
        </p>
        <p>
          根据倡议书的内容，西部国际服务贸易联盟将积极促进国际服务贸易领域商协会、专业机构及企业间的交流合作；加快创新发展、加强资源共享，不断探索服务业新模式、新业态；广泛凝聚共识、积极建言献策，努力推动国际服务贸易领域标准、规则的制定和优化；进一步推动服务业和服务贸易开放、创新、融合发展。
        </p>
        <p>
          西部国际服务贸易联盟热切期望得到国际组织、各国政府主管部门以及社会各界的大力支持，也欢迎有意愿推动国际服务贸易自由化、便利化，加强交流与合作的境内外知名商协会、跨国公司、知名企业及相关专业组织加入联盟，共同为促进国际服务贸易繁荣发展贡献智慧和力量。联盟将围绕联合开展创新研究、开展创新评估、组织创新培训和交流、设立创新基金等方面，搭建全国自贸试验片区间多方合作沟通的纽带和桥梁，通过有效的联盟机制，充分发挥各自优势，激活市场机制，开创合作共赢新模式，共同推动把自由贸易试验片区建设成为新时代改革开放的新高地。
        </p>
        <p>
          联盟将在以下三方面着力：推动国际间服务贸易领域的创新合作，包括推动国际交流，以及通过编写产业与国别报告、举办专题交流研讨等方式加强对服务贸易海外市场的分析，推动境内外企业及促进机构在投资贸易领域的交流合作；开展各类服务贸易实务探讨与项目对接，推动重大服务贸易项目落地，指导优质服务贸易项目出海运作；促进资源共享，推动成员间共享投资贸易领域各类资源，在教育培训、技术研发、投融资等企业发展关键环节开展广泛的合作，帮助企业降低成本，加速服务贸易项目的产业化、商业化。
        </p>
        <p>坚持创新发展是主线，充分发挥区域优势是特征，积极转型是主流。联盟自成立以来，积极开展信息交流和工作互动，推进服务贸易企业交流、合作，建立共享机制，做到资源和信息共享和交流。
        </p>
        <p>
          对我国来说，发展服务贸易，优化进出口结构，是长期而重要的战略任务。近年来，我国服务进出口规模不断扩大，贸易结构继续优化，区域发展协调性增强，带动外贸结构升级作用进一步显现。我国正在由服务贸易大国向服务贸易强国发展。京津沪渝四个直辖市均处于国家重点战略布局区域的中心位置，承担着引领、提升和辐射的重任。作为四个直辖市的服务贸易协会联盟，同样任重而道远。为了我国服务贸易的发展，我们将继续努力！
        </p>
      </div>
    </div>
    <div slot="rightDiv" class="right">
      <div class="photo"></div>
      <img src="../../assets/image/web.png">
    </div>
  </left-and-right>
</template>

<script>
import LeftAndRight from "@/components/public/leftAndRight";

export default {
  name: "institution",
  components: {LeftAndRight}
}
</script>

<style scoped>
.content h4 {
  margin: 10px 0;
}

.content h5 {
  margin: 0;
}

.content p {
  font-size: 14px;
  color: #666666;
  line-height: 25px;
  text-indent: 2em;
}

.right img {
  width: 100%;
}

.left p {
  font-size: 14px;
  color: #666666;
  line-height: 25px;
}

.photo {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
  background-image: url("../../assets/image/xieshougongyin.png");
  background-size: 150% 100%;
  background-position: 50% 50%;
}
</style>